import React from 'react'
import { Link } from 'react-router-dom'

export const Civicaboutus = () => {
  React.useEffect(() => {
    let currentMode = false;
    window.matchMedia('(prefers-color-scheme: dark)')?.addEventListener('change', event => {
      currentMode = event?.matches ? true : false;
    });
    var favicon = document.querySelector('link[rel="icon"]');
    favicon?.setAttribute("href", currentMode ? "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/White%20Icons-96px/Civic365%2096px%20Trans%20White_pMnITvnhR.png?updatedAt=1707741529554" :"https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-96px/Civic365%2096px%20Trans_myLSK6DmS.png?updatedAt=1707741733280")  
},[])
  return (
    
      <div className="container">
        <header className="header"><div className="mainlogo1">
        <Link to='/'> <img className="logo" src="https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Civic365%20Logo%20Color%20White%202023%20Roboto_ArBEVy62B.png?updatedAt=1706525765734" alt="logo" /></Link>
         
        </div>
      
          
        </header>
       <div className="about">
        <h3>Google OAuth2 Homepage</h3>
        <p> <b>Accurately represents your app's identity: </b>
        Civic 365 offers a revolutionary approach to managing citizen service requests. Our platform, anchored by our robust 311 CRM system, delivers streamlined, efficient, and user-friendly online solutions, fully integrated with key local government applications. Powered by Microsoft 365 and Azure, Civic365 makes tracking, reporting, and optimizing citizen issues effortless.
      </p>
      <p><b>  What will this app do with user data? </b>
      The only user data
        received is: name, email and profile picture. These will be
        saved and re-displayed to the user. The email will be used
        to identify the user. The name and profile picture will be
        used as the name and profile picture of your account on
        civic 365 App. </p>
        <p><b>How does this app enhance user functionality? </b>Transform service delivery and citizen engagement with Civic 365, a Microsoft 365 based cloud solution. Combine digital and self-service channels for exceptional service. Increase efficiency and lower costs, while boosting staff performance and workplace morale.</p>
       <p>Link to Privacy Policy:  <Link className="MB_heading" to="/privacy-policy">Click here</Link></p>
       <p><b>Describe the content, context, or connection to the app; </b>
            Our civic 365 solution empowers residents to report non-emergency issues, share feedback, and request community information easily. It simplifies and streamlines workflows for government staff, fostering a collaborative and stronger community environment.</p>
            <h3>Google OAuth2 Limited Use
              Disclosure</h3>
              <p>This app doesn't request restricted scopes, but if it did, the
                civic 365 App's use of information received from Google
                APIs will adhere to the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data
                  Policy</a>, including the Limited Use requirements.</p>
                  <p>Link to Terms and Services  <Link className="MB_heading" to="/terms-and-conditions">Click here</Link></p>
                <h3>Copyright</h3>
                <p>If you have a copyright complaint, please tell me and
                  include the civic 365 App page that contains the alleged
                  content, identification of the work claimed to have been
                  infringed including the name and reply email address of the
                  copyright holder/representative, an assertion that the use
                  of the material is not authorized and an assertion that you
                  are the copyright holder/representative.</p>
        
       </div>
      </div>
    
  
  )
}
