import React from 'react'
import { Link } from 'react-router-dom/dist'

export const Privacypolicy = () => {
  React.useEffect(() => {
    let currentMode = false;
    window.matchMedia('(prefers-color-scheme: dark)')?.addEventListener('change', event => {
      currentMode = event?.matches ? true : false;
    });
    var titleElement = document.querySelector('title');
    titleElement.textContent = 'Helpdesk 365';
    
    var favicon = document.querySelector('link[rel="icon"]');
    favicon?.setAttribute("href", currentMode ? "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/White%20Icons-96px/HD365-White-9696_QVR6wHAAh.png?updatedAt=1707739822165": "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-200px/HD365_MRfCAUv6pe.png?updatedAt=1686144360148")  
}, [])
  return (
    
  <div className="container">
  <header className="header">
    <div className="mainlogo">
    <Link to='/helpdesk'>
    <img className="logohelpdesk"
    src="https://ik.imagekit.io/zn4au2jftpm5/hr365/white%20logo/HD365%20Logo%20white%202023%20Single%20Line_cYa7_7Ye3.png?updatedAt=1707722129053"
    alt="logo" /></Link>
    
    </div>
    <div className="menu">
      <Link to='/helpdesk/about-us'>About-us</Link>
    </div>   
  </header>
  <div className='aboutmain'>
    <h3>Privacy Policy</h3>
    <p>We don't directly collect any information from you other than
      your login info from google which is used to administer
      protected access to some of our content. This app does
      include google analytics which records anonymized
      browsing behaviour and used to improve the app design.
      How does this application access, use, store, or share
      Google user data? The only user data received is: name,
      email and profile picture. These are accessed via OAuth2
      login. These are stored in an encrypted database.(Firebase
      Database) These are used only: to be re-displayed to the
      user, to reply to messages sent by the user, and as
      identification for granting access to additional content to the
      user. The app does use third party services that may collect
      information used to identify the user.
      We collect information from your device in some cases. The
      information will be utilized for the provision of better service
      and to prevent fraudulent acts. Additionally, such information
      will not include that which will identify the individual user.
      By continuing to use this app, you accept this privacy policy
      in full. If you disagree with this policy, you must not use this
      login method.</p>
      
    <h3>Security</h3>
    <p>We value your trust in providing us your Personal
      Information, thus we are striving to use commercially
      acceptable means of protecting it. But remember that no
      method of transmission over the internet, or method of
      electronic storage is 100% secure and reliable, and we
      cannot guarantee its absolute security.</p>
    <h3>Contact Us</h3>
    <p>If you have any questions or suggestions about our Privacy
      Policy, do not hesitate to contact us,</p>
    <h4>Contact Information:</h4>
    <a href="mailto:civic365@cubiclogics.com">Email: civic365@cubiclogics.com</a>



  </div>
</div>

  )
}
