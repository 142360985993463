import * as React from "react";
import DemoButtons from "./DemoButtons";
import { createBrowserRouter, NavLink, RouterProvider } from "react-router-dom";
import { Label } from "@fluentui/react";
import { Link } from "react-router-dom";

import Lightbox from "./Lightbox";
import Civicaboutus from "./Civicaboutus";
import HRfooter from "./HRFooter";

function Civic365() {




  const [isLightboxOpen, setLightboxOpen] = React.useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = React.useState("");
  React.useEffect(() => {
    let currentMode = false;
    window.matchMedia('(prefers-color-scheme: dark)')?.addEventListener('change', event => {
      currentMode = event?.matches ? true : false;
    });
    var favicon = document.querySelector('link[rel="icon"]');
    favicon?.setAttribute("href", currentMode ? "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/White%20Icons-96px/Civic365%2096px%20Trans%20White_pMnITvnhR.png?updatedAt=1707741529554" :"https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-96px/Civic365%2096px%20Trans_myLSK6DmS.png?updatedAt=1707741733280")  
},[])
  const openLightbox = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
      
      
      <div
        style={{
          position: "relative",
        }}
      >
        <div className="HR_MainHeaderSection HR_panel">
          <div className="HR_FlexCenterSpcBetween">
            <div className="HR_LogoSection">
              <a
                href="https://www.cubiclogics.com"
                target="_blank"
                rel="noreferrer"
                className="HR_logowithTExt"
              >
                <img
                  src={
                    "https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/civic_shortColored%20(1)_eyKLS1cDu.png?updatedAt=1706079031529"
                  }
                  alt="Civic365"
                />
                <Label>Civic 365</Label>
              </a>

            </div> 
            <div className="HR_FlexCenterSpcBetween" style={{ width: "20%" }}>
            <div className="MB_button">
                <div className="HR_DemoButtonsSection headerbutton">
                <div style={{
                    width: "8vw"
                }} className="HR_PrimaryButton">
                <Link  to='/aboutus'>
                    <button>About us</button>
                </Link>
            </div>
                    <div className="HR_DemoButtons">
                        <div style={{
                            width: "10vw"
                        }} className="HR_PrimaryButton">
                            <a
                                href="https://request.civic365.ca"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button>Citizen's Login</button>
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
          </div>
        </div>
      </div>
      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1">
          <div className="HR_Section1LeftSide">
            <h1 className="HR_primaryclr  ">
              Civic 365 - 311 CRM Citizen Experience Management Your Gateway to
              Efficient Citizen Engagement
            </h1>
            <p className="HR_textclr">
              Transform service delivery and citizen engagement with Civic 365,
              a Microsoft 365 based cloud solution. Combine digital and
              self-service channels for exceptional service. Increase efficiency
              and lower costs, while boosting staff performance and workplace
              morale.
            </p>

                      <div className="HR_OuterDemoButtons">
                          <div className="desktopView">
                              <DemoButtons />
                          </div>
                          <div className="HR_DemoButtons MobileView">
                    <div className="HR_PrimaryButton">
                      <a
                        href="https://request.civic365.ca"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button>Citizen's Login</button>
                      </a>
                    </div>
                  </div>
                          
            </div>
          </div>
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/01_jCrrpyeEk.jpg?updatedAt=1703939727503"
              }
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="HR_whitesection">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/06_DJAQX-fu1.jpg?updatedAt=1703940177162"
              }
              loading="lazy"
              onClick={() =>
                openLightbox(
                  "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/06_DJAQX-fu1.jpg?updatedAt=1703940177162"
                )
              }
            />
            <Lightbox
              isOpen={isLightboxOpen}
              onClose={closeLightbox}
              imageUrl={selectedImageUrl}
            />
          </div>
          <div className="HR_Section1LeftSide1">
            <h2 className="HR_primaryclr">Citizen-Focused, Service-Oriented</h2>
            <p className="HR_textclr">
              Citizens are now the priority. Globally, there's a growing
              emphasis on enhancing citizen interaction and their experiences
              with public services. Influenced by the private sector, citizens'
              expectations are shifting, making digital transformation
              fundamental to engagement strategies. Advance your public
              offerings by providing digital services crafted with the citizen's
              viewpoint in mind.
            </p>
          </div>
        </div>
      </div>

      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
          <div className="HR_Section1LeftSide1">
            <h2 className="HR_primaryclr">
              Transforming Citizen Service Request Management
            </h2>
            <p className="HR_textclr">
              Civic 365 offers a revolutionary approach to managing citizen
              service requests. Our platform, anchored by our robust 311 CRM
              system, delivers streamlined, efficient, and user-friendly online
              solutions, fully integrated with key local government
              applications. Powered by Microsoft 365 and Azure, Civic365 makes
              tracking, reporting, and optimizing citizen issues effortless.
            </p>
          </div>
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/05_dw7TuW5M-.jpg?updatedAt=1703940177015"
              }
              loading="lazy"
              onClick={() =>
                openLightbox(
                  "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/05_dw7TuW5M-.jpg?updatedAt=1703940177015"
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="HR_whitesection">
        <h1 className="HR_primaryclr">
          Ready to Elevate Your Citizen Experience?
        </h1>
        <p className="HR_textclr">
          Explore our government technology solutions today and redefine how
          your community interacts with local government services.
        </p>
        <div className="HR_OuterDemoButtons HR_MainButton">
          <DemoButtons />
        </div>
      </div>

      <HRfooter/>
    </>
  );
}
export default Civic365;
