import React from 'react'
import { Link } from 'react-router-dom/dist'

const Civicprivacy = () => {
  React.useEffect(() => {
    let currentMode = false;
    window.matchMedia('(prefers-color-scheme: dark)')?.addEventListener('change', event => {
      currentMode = event?.matches ? true : false;
    });
    var favicon = document.querySelector('link[rel="icon"]');
    favicon?.setAttribute("href", currentMode ? "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/White%20Icons-96px/Civic365%2096px%20Trans%20White_pMnITvnhR.png?updatedAt=1707741529554" :"https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-96px/Civic365%2096px%20Trans_myLSK6DmS.png?updatedAt=1707741733280")  
},[])
  return (
    <div className="container">
      <header className="header"><div className="mainlogo1">
      <Link to='/'> <img className="logo" src="https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Civic365%20Logo%20Color%20White%202023%20Roboto_ArBEVy62B.png?updatedAt=1706525765734" alt="logo" /></Link>
      </div>
      <div className="menu">
       <Link to='/about-us'>About-us</Link>
  
      </div>
        
      </header>
      <div className='aboutmain'>

        <h3>Privacy Policy</h3>
        <p>We don't directly collect any information from you other than your login info from local authentication*, Microsoft & google which is used to administer protected access to some of our content. This app does include google analytics which records anonymized browsing behavior and used to improve the app design. How does this application access, use, store, or share Google user data? The only user data received is: name, email and profile picture, these are accessed via OAuth2 login however in case of local authentication this app does not track you as you use the app.. These are stored in an encrypted database. These are used only: to be re-displayed to the user, to reply to messages sent by the user, and as identification for granting access to additional content to the user. The app does use third party services that may collect information used to identify the user. We collect information from your device in some cases. The information will be utilized for the provision of better service and to prevent fraudulent acts. Additionally, such information will not include that which will identify the individual user. By continuing to use this app, you accept this privacy policy in full. If you disagree with this policy, you must not use this login method.</p>
        <p>This app allows users to anonymize their email id while using the app thus giving the ability to keep their email as private as a part of settings.</p>
        
        <h3>Security</h3>
         <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
        <h3>Contact Us</h3>
        <p>IIf you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us,</p>
        <h4>Contact Information:</h4>
        <a href="mailto:civic365@cubiclogics.com">Email: civic365@cubiclogics.com</a>
        
        <p className='noteptag'>‘* Incase user does not want to use any of the third-party login services from Microsoft or Google, users are provided to create their login and password to use the app, this authentication is called as local authentication.</p>
        
         </div>
    </div>
  )
}

export default Civicprivacy