
import './App.css';
import Civic365 from './Components/Civic365';
import Civic365Home from './Components/Civic365Home';

function App() {
  return (
    <div className="App">
      <div className='HR_MainPage'>
        
        <Civic365Home/>
     </div>
    </div>
  );
}

export default App;
