import React from 'react'
import { Link } from 'react-router-dom'
const Aboutus = () => {

  React.useEffect(() => {
    let currentMode = false;
    window.matchMedia('(prefers-color-scheme: dark)')?.addEventListener('change', event => {
      currentMode = event?.matches ? true : false;
    });
    var titleElement = document.querySelector('title');
    titleElement.textContent = 'Helpdesk 365';
    
    var favicon = document.querySelector('link[rel="icon"]');
    favicon?.setAttribute("href", currentMode ? "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/White%20Icons-96px/HD365-White-9696_QVR6wHAAh.png?updatedAt=1707739822165": "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-200px/HD365_MRfCAUv6pe.png?updatedAt=1686144360148")  
}, [])
  
  return (
    <div className="container">
      <header className="header"><div className="mainlogo">
      <Link to='/helpdesk'>
      <img className="logohelpdesk"
      src="https://ik.imagekit.io/zn4au2jftpm5/hr365/white%20logo/HD365%20Logo%20white%202023%20Single%20Line_cYa7_7Ye3.png?updatedAt=1707722129053"
      alt="logo" /></Link>
      </div>
      
        
      </header>
     <div className="about">
      <h3>Google OAuth2 Homepage</h3>
      <p> <b>Accurately represents your app's identity: </b>Helpdesk 365 – Sharepoint ticketing system is a Microsoft ticketing system that is simple and customizable. This trouble ticket system ensures org to handle every issue within a set time frame. Assign every ticket to a specific team and have no confusion about which team should be working on it. Sharepoint helpdesk offers different ticket views to improve performance, category, time, status, or groups. Now you can use this tool for HR, IT and other teams too.
    </p>
    <p><b>What will this app do with user data? </b>
      The only user data
      received is: name, email and profile picture. These will be
      saved and re-displayed to the user. The email will be used
      to identify the user. The name and profile picture will be
      used as the name and profile picture of your account on
      Helpdesk 365.</p>
      <p><b>How does this app enhance user functionality? </b>This IT / HR ticketing system or internal help desk allows your customer to raise a ticket from the portal and further allows them to check the status of their tickets on the fly also, the sharepoint helpdesk ticketing system helps them in finding solutions faster with a knowledgebase of similar issues & resolution provided earlier. It also helps in reducing the number of tickets you receive. sharepoint ticketing system helps in empowering employees to take quick action, review and escalate as and when needed.</p>
     <p>Link to Privacy Policy:  <Link  to="/helpdesk/privacy-policy">Click here</Link></p>
    
        <p><b>Describe the content, context, or connection to the app; </b>
            Productivity & customer experience enhancer reports track team performance, and customer satisfaction and identify low-hanging fruits to improve in no time with IT & HR helpdesk 365 In reports, you can analyze the number of tickets, created, resolved, or reopened. Send customer satisfaction (CSAT) surveys as soon as the ticket is closed or resolved in IT or HR help desk</p>
          <h3>Google OAuth2 Limited Use
            Disclosure</h3>
            <p>This app doesn't request restricted scopes, but if it did, the
              Helpdesk 365 App's use of information received from Google
              APIs will adhere to the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data
                Policy</a>, including the Limited Use requirements.</p>
                <p>Link to Terms and Services <Link  to="/helpdesk/terms-and-conditions">Click here</Link></p>

              <h3>Copyright</h3>
              <p>If you have a copyright complaint, please tell me and
                include the Helpdek 365 App page that contains the alleged
                content, identification of the work claimed to have been
                infringed including the name and reply email address of the
                copyright holder/representative, an assertion that the use
                of the material is not authorized and an assertion that you
                are the copyright holder/representative.</p>
      
     </div>
    </div>
  )
}

export default Aboutus