import React from 'react'
import { Link } from 'react-router-dom'
import DemoButtons from './DemoButtons'
import '../Components/talent.css'
import { LiaRecordVinylSolid } from "react-icons/lia";
import { TfiMicrosoftAlt } from "react-icons/tfi";
import { AiOutlineAntDesign } from "react-icons/ai";
import { GrUpdate } from "react-icons/gr";
import { MdSupportAgent } from "react-icons/md";
import Plans from './Plan';

const planData = [
    {
        name: 'Standard',
        price: '$129',
        sup: '99',
        text: 'Standard Plan Features',
        textY: 'per month, billed yearly',
        features: ['50 users', 'Upto 5x assets per user', '2 support tickets per annum', 'Free updates via MS store', 'Assign Assets', 'Return Assets', 'Track assets', 'Asset life cycle history', 'Vendor tagging', 'Asset Status', 'Categories & Sub categories'],

    },
    {
        name: 'Plus',
        price: '$149',
        sup: '99',
        textY: 'per month, billed yearly',
        text: 'Everything in Standard and...',
        features: ['50 users ', 'Upto 5x assets per user', 'Unlimited support', 'Assets Dashboard', 'Configure asset attributes', 'Custom Asset status', 'Custom Asset types', 'Asset assign notifications', 'Asset return notifications', 'Bar & QR code generator'],
    },
    {
        name: 'Premium',
        price: '$199',
        sup: '99',
        textY: 'per month, billed yearly',
        text: 'Everything in Plus, and...',
        features: ['100 users ', 'Upto 5x assets per user', 'Add-on $25 for every 50 users', 'Tag Projects', 'Free updates with support', 'Custom email templates', 'MS Intune Integration', 'Depreciation', 'Book Assets', 'Bar & QR code scanner', 'Export QR Codes and Barcodes', 'Asset expiry reminders', 'Return date reminders', 'Add custom columns'],
    },
    {
        name: 'Enterprise',
        price: '$249',
        sup: '99',
        textY: 'per month, billed yearly',
        text: 'Everything in Premium and...',
        features: ['100 users', 'Upto 5x assets per user', 'Add-on $25 for every 50 users', 'Sync M365 Licenses​', 'Asset approval workflow​', 'Digitally Signed Acknowledgment', 'Desktop Central Integration​', 'SCCM Integration​', 'Maintenance Module', 'Software Module​', 'Export Audit Logs​', 'API connectivity​', 'Customize with Power Automate', 'Power BI & Power Apps Integration​', 'Location based roles', 'Custom forms', 'Dedicated Account Manager', 'One free customization*'],
    },
];
const cardsData = [

    { imgsrc: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/EO365LogoIcon_2aOTOdDJ4K.svg?updatedAt=1686137453339', heading: 'Employee Onboarding', description: 'The onboarding experience with HR365 ensures a delightful start for new joiners without overwhelming the HR team…' },
    { imgsrc: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/white%20logo/EDM365-White-2001200_5nRsh50s_7.png?updatedAt=1699247381582', heading: 'Employee Directory', description: 'The Employee Directory, an integral component of the SharePoint human resources management system…' },
    { imgsrc: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-200px/EPM365_2pADuxngle.png?updatedAt=1686144360281', heading: 'Performance Management', description: 'Your teams are the backbone of your business. Boost productivity and foster engagement among employees to…' },
    { imgsrc: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/white%20logo/expense-tracker-white_KP90-u0Js.png?updatedAt=1700915973044', heading: 'Expense Tracker', description: 'Effortlessly handle expenses using our user-friendly HR365 expense reimbursement solution, saving time, and…' },
    { imgsrc: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-200px/TM365_ga_fewo1R.png?updatedAt=1686210744805', heading: 'Time off Manager', description: 'Our Office 365 time-off manager solution seamlessly integrates into our SharePoint human resources…' },
    { imgsrc: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/white%20logo/recruitment-management-white_fvM6SL81y.png?updatedAt=1699273887737', heading: 'Recruitment Management', description: 'Recruitment Management provides diverse tools and functionalities supporting HR professionals throughout the…' },
    { imgsrc: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-200px/AM365_hvl1N9Iro-.png?updatedAt=1686144360006', heading: 'Asset Management', description: 'The HR365 SharePoint Office 365 Asset Management application offers high configurability and easy customization, allowing for seamless integration…' },
    { imgsrc: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/White%20Icons%20-%201200px/T365_TransWhite_1200px_s1JclsSHY.png?updatedAt=1699949077912', heading: 'Timesheet', description: 'Experience a powerful yet user-friendly timesheet app encompassing features such as time sheets, daily project-specific and operational tasks, and weekly reports…' },
    { imgsrc: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-96px/TaskManagement365%20-%2096Trans_n5rObW--h.png?updatedAt=1699946054339', heading: 'Task Mangement', description: 'Task Management is a straightforward and efficient task management solution fully integrated into your Microsoft 365 environment, providing…' }
];
const availabiltydata = [
    { value: 1, heading: 'Swift Deployment​', description: 'Choose any deployment method—Teams, Outlook, or SharePoint—that suits your preferences.' },
    { value: 2, heading: 'Modern UI/UX', description: 'Every app is crafted using the latest Fluent UI, ensuring seamless functionality across all devices.' },
    { value: 3, heading: 'Microsoft Appstore', description: 'Our innovative applications are available on the Microsoft Appstore' },
    { value: 4, heading: 'Free Updates', description: 'Our valued subscribers enjoy unlimited Support, enhancements, features and updates' }
]
const GCCDATA = [
    { value: 1, heading: 'Swift Deployment​', description: 'Choose any deployment method—Teams, Outlook, or SharePoint—that suits your preferences.' },
    { value: 2, heading: 'Modern UI/UX', description: 'Every app is crafted using the latest Fluent UI, ensuring seamless functionality across all devices.' },
    { value: 3, heading: 'Microsoft Appstore', description: 'Our innovative applications are available on the Microsoft Appstore' },
    { value: 4, heading: 'Free Updates', description: 'Our valued subscribers enjoy unlimited Support, enhancements, features and updates' }
]
const TalentManagement = () => {
    return (
        <div className='mainpanel'>
            <div className='Flex'>
                <div className='Leftcontainer'>
                    <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Talent%20MS%20First%20(1)_DzWQczCTi.png?updatedAt=1711195720141" alt="HR365 Talent Management" />
                </div>
                <div className='Rightcontainer '>
                    <h1 className='mainheading'>Discover, Develop, Grow & Manage the talent with <span className='mainheading2'>HR365's Human Capital Management</span></h1>

                </div>
            </div>
            <div className='Flex'>

                <div className='Rightcontainer '>
                    <h1 className='secondContainer'>Streamline the Employee Experience Seamlessly</h1>
                    <p className='pera'>Enhance your team's journey from welcome to farewell with ease</p>
                    <div className='secondContainer'>
                        <li> Discover</li>
                        <li> Develop</li>
                        <li> Grow</li>
                        <li> Manage</li>
                    </div>
                    <div>
                        <DemoButtons />
                    </div>
                </div>
                <div className='Leftcontainer'>
                    <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Talent%20Management%20System_fuqzDAOsvC.png?updatedAt=1711190478920" alt="HR365 Talent Management" />
                </div>
            </div>
            <div>
                <h1 className='subheading' style={{
                    marginBottom: "0"
                }}>
                    Prepare Your Team for Tomorrow
                </h1>
                <h1 className='subheading' style={{
                    padding: '0',
                    margin: "0"
                }}>Today with HR365</h1>
                <p className='subpera'>Transition with HR365 from hands-on HR tasks to a strategic, automated workplace that aligns with your vision for tomorrow.</p>
                <h1 className='days'>HR365 - 1 Platform 365 days of automation</h1>
            </div>

            <div>
                <Card cards={cardsData} />
            </div>
            <div className='Flex'>

                <div className='Availailty2'>
                    <div className='microsoftlogo'><MdSupportAgent /></div>
                    <h1 className='heading'>Customer Support​</h1>
                    <p className='description'>Your Support Team merges technical prowess with sincere care, fostering trust through expertise and empathy. They build lasting relationships, transcending mere service to create meaningful connections.</p>
                </div>
                <div>
                    <Availability availabilty={availabiltydata} />
                </div>
            </div>

            <div className='card-container1'>
                <div className='Flex  '>
                    <div className='Availailty4'>

                        <h1 className='heading2'>Cyber Security​</h1>
                        <p className='description1'>Cyber Security in GCC & GCC High within the Microsoft environment places high importance on stringent measures to protect sensitive government data, ensuring security with robust defenses and Microsoft applications.</p>
                    </div>

                    <div>
                        <GCC gcc={GCCDATA} />
                    </div>
                </div>
            </div>
            <h1>HR365 - Human Capital Management
            </h1>
            <h2>Choose the best plan for you</h2>
            <div>
                <Plans plans={planData} />
            </div>

        </div>



    )
    function Card({ cards }) {
        return (
            <div className="card-container  ">
                {cards.map((card, index) => (
                    <div className={`card ${index < 3 ? 'first-row' : ''}`} key={index} style={{
                        background: index % 2 === 0 ? 'linear-gradient( #fffff, #fffff)' : 'linear-gradient(To top, #2323ce, #1285f5)',
                        color: index % 2 === 0 ? '#2323ce' : '#f5f5f5',
                        border: index % 2 === 0 ? '1px solid #e1e1e7' : '0px',
                        borderRadius: '18px', padding: '2vw'
                    }}>
                        <img className='imgsize' src={card.imgsrc} alt="logo" />
                        <h2>{card.heading}</h2>
                        <p className="description">{card.description}</p>
                        <button style={{
                            background: index % 2 === 0 ? 'linear-gradient(To top, #2323ce, #1285f5)' : 'linear-gradient( #fffff, #fffff)',
                            color: index % 2 === 0 ? '#f5f5f5' : '#2323ce',
                            border: index % 2 === 0 ? '1px solid #e1e1e7' : '0px',
                            borderRadius: '0.54vw', padding: '0.9vw 2.9vw'
                        }} className="button">View More</button>
                    </div>
                ))}
            </div>
        );
    }
    function Availability({ availabilty }) {
        return (
            <>
                <div className='card-container '>
                    {availabilty?.map((Availability, index) => (

                        <div className='Availailty Flex'>
                            <div className='microsoftlogo'>

                                {Availability.value === 1 && <LiaRecordVinylSolid />}
                                {Availability.value === 2 && <TfiMicrosoftAlt />}
                                {Availability.value === 3 && <AiOutlineAntDesign />}
                                {Availability.value === 4 && <GrUpdate />}

                            </div>
                            <div>
                                <h1 className='heading'>{Availability.heading}</h1>
                                <p className='description'>{Availability.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }
    function GCC({ gcc }) {
        return (
            <>
                <div className='card-container1 '>
                    {gcc?.map((GCC, index) => (



                        <div className='Availailty3 Flex'>
                            <div className='microsoftlogo1'>

                                {GCC.value === 1 && <LiaRecordVinylSolid />}
                                {GCC.value === 2 && <TfiMicrosoftAlt />}
                                {GCC.value === 3 && <AiOutlineAntDesign />}
                                {GCC.value === 4 && <GrUpdate />}

                            </div>
                            <div>
                                <h1 className='heading1'>{GCC.heading}</h1>
                                <p className='description1'>{GCC.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }
}

export default TalentManagement